define("plutof/components/multiple-selectizer", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/utils", "@ember-decorators/object", "plutof/utils/notifications"], function (_exports, _component, _object, _runloop, _utils, _object2, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MultiSelector = (_dec = (0, _object2.observes)('selection'), _class = class MultiSelector extends _component.default {
    init() {
      super.init(...arguments);
      this.selectionChanged();
    }
    selectionChanged() {
      const selection = this.selection;
      if ((0, _utils.isNone)(selection)) {
        return;
      }
      if (this.selected.includes(selection)) {
        (0, _notifications.displayNotification)('info', 'Already in the list');
        return;
      }
      let record = selection;
      const add = this.add;
      if (!(0, _utils.isNone)(add)) {
        record = add(selection);
      }
      if (record) {
        this.selected.pushObject(record);
      }

      // TODO: Why?
      // Probably this selection change is put into the current run-loop frame, but why
      // doesn't it trigger update?
      (0, _runloop.later)(() => {
        this.set('selection', null);
      });
    }
    removeRecord(record) {
      const remove = this.remove;
      if (!(0, _utils.isNone)(remove)) {
        remove(record);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectionChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectionChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRecord", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeRecord"), _class.prototype), _class);
  var _default = _exports.default = MultiSelector;
});