define("plutof/templates/components/multiple-selection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fSvFsVkc",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"prompt\",\"selectClass\",\"disabled\"],[[30,1],[28,[37,1],[[30,2],\"id\"],null],[28,[37,1],[[30,3],\"representation\"],null],[30,0,[\"selectedValue\"]],[30,0,[\"prompt\"]],[30,0,[\"selectClass\"]],[30,4]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,0,[\"selections\"]],[30,0,[\"removeRecord\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[[30,5],[30,3]],null]],[1,\"\\n\"]],[5]]]]],[1,\"\\n\"]],[\"@content\",\"@optionValuePath\",\"@optionLabelPath\",\"@disabled\",\"item\"],false,[\"plutof-select/object\",\"default-to\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/templates/components/multiple-selection.hbs",
    "isStrictMode": false
  });
});