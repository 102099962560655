define("plutof/templates/components/multiple-selectizer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "h2dzaa09",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"optionLabelPath\",\"optionValuePath\",\"disabled\",\"value\"],[[30,1],\"display_name\",\"value\",[30,2],[30,0,[\"selection\"]]]]]],[1,\"\\n\\n\"],[41,[51,[30,3]],[[[1,\"    \"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,4],[30,0,[\"removeRecord\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,5],[30,6]],null]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[]],null]],[\"@content\",\"@disabled\",\"@hideList\",\"@selected\",\"item\",\"@nameProperty\"],false,[\"plutof-select/value\",\"unless\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/templates/components/multiple-selectizer.hbs",
    "isStrictMode": false
  });
});