define("plutof/translations/eng/transactions", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    title: 'Collection Transactions',
    description: 'In this section you can add and edit collection transactions (Accessions, Loans, Borrows).',
    newTitle: 'New Collection Transaction',
    editTitle: 'Edit Collection Transaction',
    viewTitle: 'Collection Transaction',
    agents: 'Agents',
    correspondence: 'Correspondences',
    items: 'Objects',
    type: 'Type',
    content: 'Content',
    sender: 'Sender',
    receiver: 'Receiver',
    sentDate: 'Sent date',
    receivedDate: 'Received date',
    itemId: 'ID',
    name: 'Name',
    comment: 'Comment',
    depositedAs: 'Deposited as',
    transaction: 'Transaction',
    shipments: 'Shipments',
    fillAutomatically: 'Auto fill',
    fillAutoToolt: 'Fill automatically based on data in transaction agents panel',
    number: 'Number',
    generatedNumber: 'Created automatically',
    print: 'Print',
    dnaExtraction: 'DNA extraction',
    dnaExtToolTip: 'DNA extraction is permitted, checked=Yes, unchecked=No',
    isItemReturned: 'Is returned',
    period: 'Period in months',
    editPermissionsMissing: 'You don\'t have edit permissions for {{target}}',
    isUpdating: `The transaction is currently being updated with new objects.
         Objects can be edited once the update has finished.`,
    itemTargetLabel: {
      specimen: 'Specimen ID',
      livingspecimen: 'Living specimen ID',
      materialsample: 'Material sample ID',
      photobank: 'Photobank object ID'
    },
    objects: {
      tabs: {
        databased: 'Databased',
        notDatabased: 'Not databased'
      },
      columns: {
        controls: (0, _helpers.alias)('General.controls'),
        newName: 'Taxon name add.',
        gatheringAgents: 'Collected by'
      },
      actions: {
        edit: {
          tooltip: 'Edit transaction object.'
        },
        cancel: {
          label: 'Cancel',
          tooltip: 'Cancel edit.'
        },
        save: {
          label: 'Save',
          tooltip: 'Save changes.'
        },
        delete: {
          tooltip: 'Delete transaction object.'
        },
        filter: {
          placeholder: 'Filter by ID'
        }
      }
    },
    dialogs: {
      markReturned: {
        title: 'Bulk Edit "Is returned"',
        content: 'Mark all transaction items as returned?',
        confirm: 'Mark as returned'
      },
      markDnaExtractable: {
        title: 'Bulk Edit "DNA extraction"',
        content: 'Mark that DNA extraction is permitted for all transaction objects?',
        confirm: 'Permit DNA extraction'
      },
      printing: {
        title: 'Printing options',
        ordering: 'Order records by',
        orderingChoices: {
          name: 'ID',
          taxon: 'Taxon'
        }
      }
    },
    invalidRoles: 'At least one sender and one receiver agent is required.'
  };
});