define("plutof/components/layout/regular-grid", ["exports", "@glimmer/component", "@ember/object", "@ember/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // Prefer usually to handle layout in component-specific css, but this one seems OK (probably because
  // it gets applied to generic detail views etc)
  let RegularGridLayout = (_dec = (0, _object.computed)('args.columns', 'args.gapless'), _dec2 = (0, _object.computed)('agrs.columns', 'args.static'), _class = class RegularGridLayout extends _component.default {
    get style() {
      let parts = ['display:grid'];
      if (!this.args.gapless) {
        // Same layout as bootstrap grid
        parts.push('grid-column-gap: 12px');
      }
      return (0, _template.htmlSafe)(parts.join('; '));
    }
    get dynamicGridClass() {
      return `dynamic-grid-${this.args.columns}`;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "style", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "style"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dynamicGridClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dynamicGridClass"), _class.prototype), _class);
  var _default = _exports.default = RegularGridLayout;
});