define("plutof/components/clipboard/living-specimen/label-printing", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "@ember/service", "ember-concurrency", "plutof/config/environment", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _service, _emberConcurrency, _environment, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#@tabGroup.panel}}
      <div class="form-group">
          <Common::LocalLabel @label="Clipboard.labelFormat" />
  
          <PlutofSelect::Value
              @content={{this.labelFormats}}
              @value={{this.labelFormat}} />
      </div>
  {{/@tabGroup.panel}}
  
  <div class="action-buttons spacer-large-above">
      <PromiseButton
          @clicked={{this.printLabels}}
          @disabled={{this.printingDisabled}}
          @restIcon={{icon "download"}}
          class="btn btn-default plutof-btn-green"
      >
          {{i18n-t "Clipboard.actions.printLabels.label"
              count=(if @selectedEntries.length (locale-string @selectedEntries.length) (i18n-t 'Clipboard.all'))}}
      </PromiseButton>
  </div>
  
  */
  {
    "id": "wJfWLCvl",
    "block": "[[[6,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@label\"],[\"Clipboard.labelFormat\"]],null],[1,\"\\n\\n        \"],[8,[39,1],null,[[\"@content\",\"@value\"],[[30,0,[\"labelFormats\"]],[30,0,[\"labelFormat\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"action-buttons spacer-large-above\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn btn-default plutof-btn-green\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,0,[\"printLabels\"]],[30,0,[\"printingDisabled\"]],[28,[37,3],[\"download\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[\"Clipboard.actions.printLabels.label\"],[[\"count\"],[[52,[30,2,[\"length\"]],[28,[37,6],[[30,2,[\"length\"]]],null],[28,[37,4],[\"Clipboard.all\"],null]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tabGroup\",\"@selectedEntries\"],false,[\"common/local-label\",\"plutof-select/value\",\"promise-button\",\"icon\",\"i18n-t\",\"if\",\"locale-string\"]]",
    "moduleName": "plutof/components/clipboard/living-specimen/label-printing.hbs",
    "isStrictMode": false
  });
  const FORMAT_ENDPOINTS = {
    'tu-botanical-garden': 'export/livingcultures/labels/tu-botanical-garden',
    'tu-botanical-garden-orchids': 'export/livingcultures/labels/tu-botanical-garden-orchids'
  };
  function downloadLabels(content) {
    const a = document.createElement('a');
    const blob = new Blob([content], {
      type: 'text/html'
    });
    a.href = URL.createObjectURL(blob);
    a.download = 'labels.html';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
  let LivingSpecimenLabelPrinting = (_dec = (0, _object.computed)('args.selectedEntries.length', 'args.totalCount'), _dec2 = (0, _object.computed)('labelFormat', 'entriesCount'), _dec3 = (0, _object.computed)('session.isAuthenticated', 'session.data.authenticated.access_token'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class LivingSpecimenLabelPrinting extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "locale", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _defineProperty(this, "labelFormats", [{
        display_name: 'TU Botanical Garden',
        value: 'tu-botanical-garden'
      }, {
        display_name: 'TU Botanical Garden Orchids',
        value: 'tu-botanical-garden-orchids'
      }]);
      _initializerDefineProperty(this, "fetchLabels", _descriptor4, this);
    }
    get entriesCount() {
      return (0, _object.get)(this.args, 'selectedEntries.length') || this.args.totalCount;
    }
    get printingDisabled() {
      return (0, _utils.isNone)(this.labelFormat) || this.entriesCount === 0;
    }

    // TODO: Fetch service.
    get headers() {
      let headers = {
        'Content-Type': 'application/json',
        'Accept-Language': this.locale.locale
      };
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      return headers;
    }
    printLabels() {
      return this.fetchLabels.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "entriesCount", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "entriesCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printingDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "printingDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetchLabels", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const format = this.labelFormat;
        const endpoint = FORMAT_ENDPOINTS[format];
        const objects = this.args.selectedEntries.mapBy('id');
        try {
          const response = yield fetch(`${_environment.default.API_HOST}/${endpoint}/`, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify({
              object_list: objects
            })
          });
          if (response.ok) {
            downloadLabels(yield response.text());
            (0, _notifications.displayNotification)('success', this.i18n.translate('Clipboard.labelPrinting.downloadStarted'));
          } else {
            (0, _notifications.displayNotification)('error', response.statusText);
          }
        } catch (err) {
          (0, _notifications.reportError)(err);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "printLabels", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "printLabels"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LivingSpecimenLabelPrinting);
});