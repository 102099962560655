define("plutof/templates/components/my-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AasRUrsP",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\"],[[30,0,[\"personalMenu\",\"isOpen\"]],[30,0,[\"personalMenu\",\"close\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"General.myMenu\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[6,[39,2],null,[[\"activeName\"],[[30,0,[\"personalMenu\",\"currentTool\"]]]],[[\"default\"],[[[[6,[30,1,[\"tool\"]],null,[[\"name\",\"title\",\"icon\"],[\"bookmarks\",[28,[37,1],[\"General.bookmarksAndSearches\"],null],\"icon-bookmark\"]],[[\"default\"],[[[[1,\"                \"],[1,[28,[35,3],null,[[\"bookmarks\",\"isLoading\",\"remove\"],[[30,0,[\"bookmarksList\"]],false,[28,[37,4],[[30,0],\"removeBookmark\"],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"tool\"]],null,[[\"name\",\"title\",\"icon\"],[\"keywords\",[28,[37,1],[\"Glossary.title\"],null],\"icon-search\"]],[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"tool\"]],null,[[\"name\",\"title\",\"icon\"],[\"measurements\",[28,[37,1],[\"General.measurements\"],null],\"icon-search\"]],[[\"default\"],[[[[1,\"                \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"tool\"]],null,[[\"name\",\"title\",\"icon\"],[\"coordinate-converter\",[28,[37,1],[\"General.Converter\"],null],\"fas fa-map-marker-alt\"]],[[\"default\"],[[[[1,\"                \"],[8,[39,7],null,null,null],[1,\"\\n\"]],[]]]]]],[1]]]]],[1,\"    \"]],[]],[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,8],[\"click\",[30,0,[\"personalMenu\",\"close\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.Close\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"menu\"],false,[\"ui/modal/dialog\",\"i18n-t\",\"my-menu/tool-selector\",\"profile/bookmark-list\",\"action\",\"my-menu/keywords\",\"my-menu/measurements\",\"my-menu/coordinate-converter\",\"on\"]]",
    "moduleName": "plutof/templates/components/my-menu.hbs",
    "isStrictMode": false
  });
});