define("plutof/components/collection-lab/permit/items/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "rsvp", "plutof/utils/modules", "plutof/models/permit/item"], function (_exports, _component, _templateFactory, _component2, _object, _rsvp, _modules, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createPermitItemsEditModel = createPermitItemsEditModel;
  _exports.default = void 0;
  _exports.loadPermitItemsEditModel = loadPermitItemsEditModel;
  var _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="out-of-form-field">
      <CollectionLab::Permit::Autocomplete @update={{this.addPermit}} />
  </div>
  
  <div class="spacer-large-below"></div>
  
  <Tables::List
      @records={{@model.entries}}
      as |Field entry|
  >
      <Field @label={{backend-translations/label "permit.permit.name"}}>
          {{entry.permit.name}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.status"}}>
          {{entry.permit.statusDisplayName}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.permit_type"}}>
          {{entry.permit.permitTypeDisplayName}}
      </Field>
  
      <Field>
          <Tables::RemoveButton @clicked={{fn @model.remove entry}} />
      </Field>
  </Tables::List>
  
  */
  {
    "id": "bTACH3Qc",
    "block": "[[[10,0],[14,0,\"out-of-form-field\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@update\"],[[30,0,[\"addPermit\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@records\"],[[30,1,[\"entries\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"permit.permit.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"permit\",\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"permit.permit.status\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"permit\",\"statusDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"permit\",\"permitTypeDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@clicked\"],[[28,[37,4],[[30,1,[\"remove\"]],[30,3]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@model\",\"Field\",\"entry\"],false,[\"collection-lab/permit/autocomplete\",\"tables/list\",\"backend-translations/label\",\"tables/remove-button\",\"fn\"]]",
    "moduleName": "plutof/components/collection-lab/permit/items/edit.hbs",
    "isStrictMode": false
  });
  let PermitItemsEditModel = (_class = class PermitItemsEditModel {
    constructor(_ref2) {
      let {
        store
      } = _ref2;
      // :: [{ permit, items :: ctype -> id -> item? }]
      _defineProperty(this, "entries", []);
      _defineProperty(this, "toDelete", []);
      this.store = store;
    }
    async addItem(item) {
      const permit = await item.permit;
      const entry = this._getPermitEntry(permit);
      this._registerItem(entry, item);
    }
    addPermit(permit) {
      this._getPermitEntry(permit);
    }
    remove(entry) {
      for (const items of entry.items.values()) {
        for (const item of items.values()) {
          this.toDelete.push(item);
        }
      }
      this.entries.removeObject(entry);
    }
    async save(record) {
      await _rsvp.default.all(this.toDelete.map(r => r.destroyRecord()));
      this.toDelete = [];
      await _rsvp.default.all(this.entries.map(entry => {
        const item = this._registerRecord(entry, record);
        if (item.isNew) {
          return item.save();
        }
      }));
    }
    _getPermitEntry(permit) {
      let entry = this.entries.find(entry => entry.permit === permit);
      if (!entry) {
        entry = {
          permit,
          items: new Map()
        };
        this.entries.pushObject(entry);
      }
      return entry;
    }
    _getContentTypeItems(entry, ctype) {
      let ids = entry.items.get(ctype);
      if (!ids) {
        ids = new Map();
        entry.items.set(ctype, ids);
      }
      return ids;
    }
    _registerRecord(entry, record) {
      let ctype = (0, _modules.getRecordContentType)(record);

      // XXX: We still need "agent/collection" as a distinct type
      // from agent/organization, even thought they are the same on
      // back end. Hopefully, not for long, otherwise will need to
      // introduce the module<->ctype binding
      if (ctype === 'agent/collection') {
        ctype = 'agent/organization';
      }
      const items = this._getContentTypeItems(entry, ctype);
      if (!items.has(record.id)) {
        const item = this.store.createRecord('permit/item', {
          content_type: ctype,
          object_id: record.id,
          permit: entry.permit
        });
        items.set(record.id, item);
      }
      return items.get(record.id);
    }
    _registerItem(entry, item) {
      const items = this._getContentTypeItems(entry, item.content_type);
      if (items.has(item.object_id.toString())) {
        throw new Error('Programmer error: preexisting item is already registered');
      }
      items.set(item.object_id.toString(), item);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "addPermit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPermit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  function createPermitItemsEditModel(store) {
    return new PermitItemsEditModel({
      store
    });
  }
  async function loadPermitItemsEditModel(store, record) {
    const model = new PermitItemsEditModel({
      store
    });
    const items = await (0, _item.loadPermitItems)(record);
    let ctype = (0, _modules.getRecordContentType)(record);
    if (ctype === 'agent/collection') {
      ctype = 'agent/organization';
    }
    const ownItems = items.filter(item => item.content_type === ctype && item.object_id.toString() === record.id);
    for (const item of ownItems) {
      await model.addItem(item);
    }
    return model;
  }
  let PermitItemsEdit = (_class2 = class PermitItemsEdit extends _component2.default {
    addPermit(permit) {
      if (permit) {
        this.args.model.addPermit(permit);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "addPermit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "addPermit"), _class2.prototype), _class2);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PermitItemsEdit);
});