define("plutof/templates/components/organization-label-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sLG7R1jO",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[[30,0,[\"labelClasses\"]]]]],[12],[1,\"\\n        \"],[1,[30,0,[\"data\",\"labelText\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,1],[15,1,[29,[[30,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"data\",\"tooltipText\"]]],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\\n        \"],[1,[28,[35,2],[\"Agents.organizationTooltip\"],null]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"data\",\"infoLinks\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,5],null,[[\"infoLink\"],[[30,2,[\"url\"]]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"info\"],false,[\"tooltip-wrapper\",\"break-line\",\"i18n-t\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/templates/components/organization-label-tooltip.hbs",
    "isStrictMode": false
  });
});