define("plutof/components/dmp/view/dataset-statistics", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency", "ember-data", "plutof/misc/abstract"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberConcurrency, _emberData, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "dmp.panels.statistics"}}>
      <div class="chart-container">
          <Chart::HistogramChart
              @config={{this.chartConfig}} />
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "5TbRhB20",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.statistics\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"chart-container\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@config\"],[[30,0,[\"chartConfig\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"plutof-panel/simple\",\"i18n-t\",\"chart/histogram-chart\"]]",
    "moduleName": "plutof/components/dmp/view/dataset-statistics.hbs",
    "isStrictMode": false
  });
  async function countCitations(datasetCitations) {
    const citations = await datasetCitations;
    return citations.compact().reduce((acc, dataset) => {
      const type = dataset.dataset.type;
      const count = dataset.count;
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type] += count;
      return acc;
    }, {});
  }
  function countTypes(datasets) {
    return datasets.reduce((acc, dataset) => {
      const type = dataset.type;
      if (type && !acc[type]) {
        acc[type] = 0;
      }
      acc[type]++;
      return acc;
    }, {});
  }
  let DMPDatasetStatistics = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec2 = (0, _object.computed)('reloadCitationsData.isRunning', 'citationsData'), _dec3 = (0, _object.computed)('citationsData'), _class = class DMPDatasetStatistics extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "citationsData", _descriptor2, this);
      _initializerDefineProperty(this, "reloadCitationsData", _descriptor3, this);
      this.typesData = countTypes(this.args.datasets);
      this.reloadCitationsData.perform();
    }
    get isLoading() {
      return this.reloadCitationsData.isRunning || !this.citationsData;
    }
    get chartConfig() {
      const citationsData = this.citationsData || {};
      const citationsLabel = this.reloadCitationsData.isRunning ? this.i18n.translate('dmp.chart.loadingCitationsChart') : this.i18n.translate('dmp.chart.citationsChart');
      return _emberData.default.PromiseObject.create({
        promise: (0, _abstract.wrap_as_promise)({
          context: this,
          data: {
            datasets: {
              data: [{
                key: this.i18n.translate('dmp.chart.datasetsChart'),
                module: {
                  data: Object.entries(this.typesData).map(_ref2 => {
                    let [key, count] = _ref2;
                    return {
                      count,
                      key,
                      label: key
                    };
                  })
                }
              }, {
                key: citationsLabel,
                module: {
                  data: Object.entries(citationsData).map(_ref3 => {
                    let [key, count] = _ref3;
                    return {
                      count,
                      key,
                      label: key
                    };
                  })
                }
              }]
            }
          },
          field: {
            key: 'datasets',
            label: this.i18n.translate('dmp.chart.datasetsTitle')
          },
          type: 'clustered',
          options: {
            aspectRatio: 3
          }
        })
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "citationsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reloadCitationsData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.citationsData = yield countCitations(this.args.datasetCitations);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isLoading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chartConfig", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "chartConfig"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPDatasetStatistics);
});