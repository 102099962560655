define("plutof/components/chart/histogram-chart", ["exports", "@ember/component", "@ember/object", "@ember/utils", "plutof/utils/chart", "plutof/utils/i18n"], function (_exports, _component, _object, _utils, _chart, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  const SUPPORTED_INTERVAL_FILTERS = ['year', 'month', 'day'];
  function getRegularData(_ref) {
    let {
      context,
      data,
      field
    } = _ref;
    return [{
      label: field.label,
      backgroundColor: '#98cc99',
      borderColor: '#98cc99',
      pointHoverRadius: 3,
      data: data[field.key].data.mapBy('count')
    }];
  }
  function getClusteredData(_ref2) {
    let {
      context,
      data,
      field
    } = _ref2;
    const datasets = [];
    const dataContainer = data[field.key].data;
    const modules = dataContainer.mapBy('module');
    let datasetKeys = [];

    // Getting unique keys for each cluster
    modules.forEach(module => {
      datasetKeys = datasetKeys.concat(module.data.mapBy('key'));
    });
    datasetKeys.uniq().forEach((key, index) => {
      const color = (0, _chart.getChartColor)(key, index);
      const labelKey = /^.*-\d+$/.test(key) ? key.slice(0, key.lastIndexOf('-')) : key;
      const label = i18n.t(`chart.${labelKey}`, {
        quiet: true
      }) || labelKey;
      const cluster = {
        label,
        backgroundColor: color,
        borderColor: color,
        pointHoverRadius: 3,
        data: [],
        isEmpty: false
      };
      modules.forEach(module => {
        const matchedElement = module.data.findBy('key', key);
        const count = matchedElement ? matchedElement.count : 0;
        cluster.data.push(count);
      });

      // Dont show datasets that only contains 0's
      if (!(0, _utils.isEmpty)(cluster.data.filter(Boolean))) {
        datasets.pushObject(cluster);
      }
    });
    return datasets;
  }
  function getLabelForAxis(interval, field) {
    return interval ? `${i18n.t('chart.time')} (${i18n.t('chart.' + interval)})` : i18n.t(`chart.${field.key}`, {
      quiet: true
    });
  }
  function getScaleLabel(interval, field) {
    const scaleLabel = {
      display: true
    };
    const labelString = getLabelForAxis(interval, field);
    if (labelString) {
      scaleLabel.labelString = labelString;
    }
    return scaleLabel;
  }
  function createHistogramOptions(_ref3) {
    let {
      context,
      data,
      field,
      type,
      options = {}
    } = _ref3;
    // Fail request handling.
    if ((0, _utils.isEmpty)(data)) {
      return {};
    }
    const interval = data[field.key].calendar_interval;
    return Object.assign({
      responsive: true,
      title: {
        display: true,
        text: field.label
      },
      legend: {
        display: type === 'clustered' || type === 'stacked',
        labels: {
          fontSize: 10,
          boxWidth: 10
        }
      },
      onClick: function (event, items) {
        // We can not add filters of unsupported intervals.
        if (SUPPORTED_INTERVAL_FILTERS.includes(interval) && context.handleClick) {
          context.handleClick(event, items, interval, field.key);
        }
      },
      scales: {
        xAxes: [{
          stacked: type === 'stacked',
          scaleLabel: getScaleLabel(interval, field),
          ticks: {
            autoSkipPadding: 11,
            maxRotation: 90,
            minRotation: 0
          }
        }],
        yAxes: [{
          stacked: type === 'stacked',
          ticks: {
            beginAtZero: true,
            // XXX This is a hack to show only integer values on the y-axis.
            callback: function (value) {
              return value % 1 === 0 ? value : '';
            }
          },
          scaleLabel: {
            display: true,
            labelString: i18n.t('chart.count')
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            if (data.datasets.length === 1) {
              return getSingularLabel(tooltipItem, data);
            } else {
              return getLabel(tooltipItem, data);
            }
          }
        }
      },
      onResize(chart) {
        if (chart.width < 250) {
          chart.aspectRatio = 0.5;
        } else if (chart.width < 600) {
          chart.aspectRatio = 1;
        } else {
          chart.aspectRatio = options.aspectRatio || 2;
        }
      }
    }, options);
  }
  function getSingularLabel(tooltipItem, data) {
    return i18n.t('chart.count') + ': ' + tooltipItem.yLabel;
  }
  function getLabel(tooltipItem, data) {
    return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
  }
  function getDataMethod(type) {
    const methods = {
      regular: getRegularData,
      clustered: getClusteredData,
      stacked: getClusteredData
    };
    return methods[type];
  }
  function createHistogramConfig(_ref4) {
    let {
      context,
      data,
      field,
      type,
      options
    } = _ref4;
    // Fail request handling.
    if ((0, _utils.isEmpty)(data)) {
      return {};
    }
    const interval = data[field.key].calendar_interval;
    const dataMethod = getDataMethod(type);
    const dataContainer = data[field.key].data;
    return {
      type: 'bar',
      data: {
        labels: (0, _chart.getLabels)(dataContainer, interval),
        datasets: dataMethod(...arguments)
      },
      options: createHistogramOptions(...arguments)
    };
  }
  let HistogramChart = (_dec = (0, _object.computed)('config.isFulfilled'), _class = class HistogramChart extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "config", null);
    }
    get chartConfig() {
      const config = this.config;
      if (this.get('config.isFulfilled')) {
        return createHistogramConfig(config.getProperties('context', 'data', 'field', 'type', 'options'));
      } else {
        return null;
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "chartConfig", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chartConfig"), _class.prototype), _class);
  var _default = _exports.default = HistogramChart;
});