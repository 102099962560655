define("plutof/templates/components/chart/line-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ATPpR741",
    "block": "[[[41,[30,0,[\"chartConfig\",\"type\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@type\",\"@options\",\"@data\"],[[30,0,[\"chartConfig\",\"type\"]],[30,0,[\"chartConfig\",\"options\"]],[30,0,[\"chartConfig\",\"data\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[],false,[\"if\",\"chart/base-chart\"]]",
    "moduleName": "plutof/templates/components/chart/line-chart.hbs",
    "isStrictMode": false
  });
});