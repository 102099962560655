define("plutof/components/chart/base-chart", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember-decorators/component", "chart.js"], function (_exports, _component, _object, _runloop, _component2, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let BaseChart = (_dec = (0, _component2.tagName)('canvas'), _dec2 = (0, _component2.attributeBindings)('width', 'height'), _dec(_class = _dec2(_class = class BaseChart extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      const context = this.element;
      const data = this.data;
      const type = this.type;
      const options = this.options;
      const chart = new _chart.default(context, {
        type: type,
        data: (0, _object.getProperties)(data, Object.keys(data)),
        options: options
      });
      this.set('chart', chart);

      // TODO: Debounce or will it be more fun if we don't?
      this.addObserver('data', this, this.updateChart);
      this.addObserver('data.[]', this, this.updateChart);
      this.addObserver('options', this, this.updateChart);
      (0, _runloop.scheduleOnce)('afterRender', this, this.resizeChart);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.chart.destroy();
      this.removeObserver('data', this, this.updateChart);
      this.removeObserver('data.[]', this, this.updateChart);
      this.removeObserver('options', this, this.updateChart);
    }
    updateChart() {
      const chart = this.chart;
      const data = this.data;
      chart.config.data = data;
      chart.update();
    }
    resizeChart() {
      if (this.chart.options.onResize) {
        this.chart.options.onResize(this.chart);
      }
    }
  }) || _class) || _class);
  var _default = _exports.default = BaseChart;
});